import { Navigate, UIMatch, generatePath } from 'react-router-dom'

import { Link } from '@mui/material'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { routePaths } from './route-paths'
import { prepareRoutes } from './utils/prepareRoutes'

export const useFileMonitoringRoutes = () => {
	const { transformers8490, transformers11348, transformers8737 } = useFlags()

	const routes = prepareRoutes(
		[
			// NOTE: put all file-line detail pages above this if using /file-monitoring in the path
			{
				lazy: () => import('pages/file-monitoring').then((module) => ({ Component: module.default })),
				handle: {
					crumb: () => <Link href={routePaths.fileMonitoring.root}>File Monitoring</Link>,
				},
				path: `${routePaths.fileMonitoring.root}/*`,
				children: prepareRoutes(
					[
						{
							index: true,
							element: <Navigate to={routePaths.fileMonitoring.unprocessed.path} replace />,
						},
						{
							path: routePaths.fileMonitoring.unprocessed.path,
							lazy: () =>
								import('pages/file-monitoring/unprocessed-files').then((module) => ({
									Component: module.UnprocessedFiles,
								})),
							handle: {
								crumb: () => <Link href={routePaths.fileMonitoring.unprocessed.path}>Unprocessed Files</Link>,
							},
						},
						{
							path: routePaths.fileMonitoring.overdue.path,
							lazy: () =>
								import('pages/file-monitoring/overdue-files').then((module) => ({
									Component: module.OverdueFiles,
								})),
							handle: {
								crumb: () => <Link href={routePaths.fileMonitoring.overdue.path}>Overdue Files</Link>,
							},
						},
						{
							path: `${routePaths.fileMonitoring.inboundCensus.path}/*`,
							handle: {
								crumb: () => <Link href={routePaths.fileMonitoring.inboundCensus.path}>Inbound Census</Link>,
							},
							children: prepareRoutes(
								[
									{
										index: true,
										lazy: () => import('pages/census-summary').then((module) => ({ Component: module.default })),
									},
									{
										path: routePaths.fileMonitoring.inboundCensus.file.path,
										handle: {
											crumb: (data: UIMatch<any, any>) => (
												<Link
													href={generatePath(routePaths.fileMonitoring.inboundCensus.file.path, {
														id: `${data.params.id ?? ''}`,
													})}
												>
													{data.params.id ?? '[id]'}
												</Link>
											),
										},
										children: prepareRoutes(
											[
												{
													index: true,
													lazy: () =>
														import('pages/file-monitoring/inbound-census/inbound-census-file-monitoring-page').then(
															(module) => ({ Component: module.default }),
														),
												},
												{
													lazy: () => import('pages/census-errors').then((module) => ({ Component: module.default })),
													path: routePaths.fileMonitoring.inboundCensus.file.errors.path,
													handle: {
														crumb: (data: UIMatch<any, any>) => (
															<Link
																href={generatePath(routePaths.fileMonitoring.inboundCensus.file.errors.path, {
																	id: `${data.params.id ?? ''}`,
																})}
															>
																Census Errors
															</Link>
														),
													},
												},
												{
													lazy: () => import('pages/census-warnings').then((module) => ({ Component: module.default })),
													path: routePaths.fileMonitoring.inboundCensus.file.warnings.path,
													handle: {
														crumb: (data: UIMatch<any, any>) => (
															<Link
																href={generatePath(routePaths.fileMonitoring.inboundCensus.file.warnings.path, {
																	id: `${data.params.id ?? ''}`,
																})}
															>
																Census Warnings
															</Link>
														),
													},
												},
												{
													lazy: () =>
														import('pages/census-successes').then((module) => ({ Component: module.default })),
													path: routePaths.fileMonitoring.inboundCensus.file.success.path,
													handle: {
														crumb: (data: UIMatch<any, any>) => (
															<Link
																href={generatePath(routePaths.fileMonitoring.inboundCensus.file.success.path, {
																	id: `${data.params.id ?? ''}`,
																})}
															>
																Census Successes
															</Link>
														),
													},
												},
												{
													lazy: () => import('pages/census-missing').then((module) => ({ Component: module.default })),
													path: routePaths.fileMonitoring.inboundCensus.file.missing.path,
													handle: {
														crumb: (data: UIMatch<any, any>) => (
															<Link
																href={generatePath(routePaths.fileMonitoring.inboundCensus.file.missing.path, {
																	id: `${data.params.id ?? ''}`,
																})}
															>
																Census Missing Employees
															</Link>
														),
													},
												},
											],
											{
												stripLeading: `${routePaths.fileMonitoring.inboundCensus.file.path}/`,
											},
										),
									},
								],
								{
									stripLeading: `${routePaths.fileMonitoring.inboundCensus.path}/`,
								},
							),
						},
						{
							path: routePaths.fileMonitoring.inboundElections.path,
							handle: {
								crumb: () => <Link href={routePaths.fileMonitoring.inboundElections.path}>Inbound Elections</Link>,
							},
							children: prepareRoutes(
								[
									{
										index: true,
										lazy: () =>
											import('pages/inbound-elections-file-summary').then((module) => ({
												Component: module.default,
											})),
									},
									{
										path: routePaths.fileMonitoring.inboundElections.file.path,
										handle: {
											crumb: (data: UIMatch<any, any>) => (
												<Link
													href={generatePath(routePaths.fileMonitoring.inboundElections.file.path, {
														fileId: data.params.fileId ?? '',
													})}
												>
													{data.params.fileId ?? '[fileId]'}
												</Link>
											),
										},
										children: prepareRoutes(
											[
												{
													index: true,
													lazy: () =>
														import(
															'pages/file-monitoring/inbound-elections/inbound-elections-file-monitoring-details-page'
														).then((module) => ({ Component: module.default })),
												},
												{
													lazy: () =>
														import('pages/inbound-elections-file-lines').then((module) => ({
															Component: module.default,
														})),
													flag: transformers8737,
													path: routePaths.fileMonitoring.inboundElections.file.lineDetails.path,
													handle: {
														crumb: (data: UIMatch<any, any>) => (
															<Link
																href={generatePath(routePaths.fileMonitoring.inboundElections.file.lineDetails.path, {
																	fileId: data.params.fileId ?? '',
																	status: data.params.status ?? '',
																})}
															>
																Inbound Election {data.params.status ?? '[status]'}
															</Link>
														),
													},
												},
											],
											{
												stripLeading: `${routePaths.fileMonitoring.inboundElections.file.path}/`,
											},
										),
									},
								],
								{ stripLeading: `${routePaths.fileMonitoring.inboundElections.path}/` },
							),
						},
						{
							path: `${routePaths.fileMonitoring.outboundElections.path}/*`,
							handle: {
								crumb: () => <Link href={routePaths.fileMonitoring.outboundElections.path}>Outbound Elections</Link>,
							},
							children: prepareRoutes(
								[
									{
										index: true,
										lazy: () =>
											import('pages/outbound-election-file-summary').then((module) => ({ Component: module.default })),
									},
									{
										path: routePaths.fileMonitoring.outboundElections.file.path,
										handle: {
											crumb: (data: UIMatch<any, any>) => (
												<Link
													href={generatePath(routePaths.fileMonitoring.outboundElections.file.path, {
														electionFileId: data.params.electionFileId ?? '',
													})}
												>
													{data.params.electionFileId ?? '[electionFileId]'}
												</Link>
											),
										},
										children: prepareRoutes(
											[
												{
													index: true,
													lazy: () =>
														import(
															'pages/file-monitoring/outbound-elections/outbound-elections-file-monitoring-details-page'
														).then((module) => ({ Component: module.default })),
												},
												{
													lazy: () =>
														import('pages/outbound-election-file-line-summary-table').then((module) => ({
															Component: module.default,
														})),
													path: routePaths.fileMonitoring.outboundElections.file.lineDetails.path,
													handle: {
														crumb: (data: UIMatch<any, any>) => (
															<Link
																href={generatePath(routePaths.fileMonitoring.outboundElections.file.lineDetails.path, {
																	electionFileId: data.params.electionFileId ?? '',
																	lineStatusType: data.params.lineStatusType ?? '',
																})}
															>
																Outbound Election {data.params.lineStatusType ?? '[lineStatusType]'}
															</Link>
														),
													},
												},
											],
											{
												stripLeading: `${routePaths.fileMonitoring.outboundElections.file.path}/`,
											},
										),
									},
								],
								{
									stripLeading: `${routePaths.fileMonitoring.outboundElections.path}/`,
								},
							),
						},
						{
							path: `${routePaths.fileMonitoring.outboundCensus.path}/*`,
							handle: {
								crumb: () => <Link href={routePaths.fileMonitoring.outboundCensus.path}>Outbound Census</Link>,
							},
							children: prepareRoutes(
								[
									{
										index: true,
										lazy: () =>
											import('pages/outbound-census-summary').then((module) => ({ Component: module.default })),
									},
									{
										path: routePaths.fileMonitoring.outboundCensus.file.path,
										handle: {
											crumb: (data: UIMatch<any, any>) => (
												<Link
													href={generatePath(routePaths.fileMonitoring.outboundCensus.file.path, {
														id: data.params.id ?? '',
													})}
												>
													{data.params.id ?? '[id]'}
												</Link>
											),
										},
										children: prepareRoutes(
											[
												{
													index: true,
													lazy: () =>
														import(
															'pages/file-monitoring/outbound-census/outbound-census-file-monitoring-details-page'
														).then((module) => ({ Component: module.default })),
												},
												{
													lazy: () =>
														import('pages/outbound-census-errors').then((module) => ({ Component: module.default })),
													path: routePaths.fileMonitoring.outboundCensus.file.errors.path,
													handle: {
														crumb: (data: UIMatch<any, any>) => (
															<Link
																href={generatePath(routePaths.fileMonitoring.outboundCensus.file.errors.path, {
																	id: data.params.id ?? '',
																})}
															>
																Outbound Census Errors
															</Link>
														),
													},
												},
												{
													lazy: () =>
														import('pages/outbound-census-successes').then((module) => ({ Component: module.default })),
													path: routePaths.fileMonitoring.outboundCensus.file.success.path,
													handle: {
														crumb: (data: UIMatch<any, any>) => (
															<Link
																href={generatePath(routePaths.fileMonitoring.outboundCensus.file.success.path, {
																	id: data.params.id ?? '',
																})}
															>
																Outbound Census Successes
															</Link>
														),
													},
												},
											],
											{ stripLeading: `${routePaths.fileMonitoring.outboundCensus.file.path}/` },
										),
									},
								],
								{ stripLeading: `${routePaths.fileMonitoring.outboundCensus.path}/` },
							),
						},
						{
							path: `${routePaths.fileMonitoring.providerBills.path}/*`,
							handle: {
								crumb: () => <Link href={routePaths.fileMonitoring.providerBills.path}>Provider Bills</Link>,
							},
							children: prepareRoutes(
								[
									{
										index: true,
										lazy: () =>
											import('pages/provider-bill-file-summary').then((module) => ({ Component: module.default })),
									},
									{
										path: routePaths.fileMonitoring.providerBills.file.path,
										handle: {
											crumb: (data: UIMatch<any, any>) => (
												<Link
													href={generatePath(routePaths.fileMonitoring.providerBills.file.path, {
														fileId: data.params.fileId ?? '',
													})}
												>
													{data.params.fileId ?? '[fileId]'}
												</Link>
											),
										},
										children: prepareRoutes(
											[
												{
													index: true,
													lazy: () =>
														import(
															'pages/file-monitoring/provider-bill/provider-bill-file-monitoring-details-page'
														).then((module) => ({ Component: module.default })),
												},
												{
													path: routePaths.fileMonitoring.providerBills.file.lineDetails.path,
													flag: transformers8737,
													lazy: () =>
														import('pages/provider-bill-file-lines').then((module) => ({
															Component: module.default,
														})),
													handle: {
														crumb: (data: UIMatch<any, any>) => (
															<Link
																href={generatePath(routePaths.fileMonitoring.providerBills.file.lineDetails.path, {
																	fileId: data.params.fileId ?? '',
																	status: data.params.status ?? '',
																})}
															>
																Provider Bill {data.params.status ?? '[status]'}
															</Link>
														),
													},
												},
											],
											{
												stripLeading: `${routePaths.fileMonitoring.providerBills.file.path}/`,
											},
										),
									},
								],
								{
									stripLeading: `${routePaths.fileMonitoring.providerBills.path}/`,
								},
							),
						},
						{
							path: `${routePaths.fileMonitoring.payrollDeduction.path}/*`,
							flag: transformers8490,
							handle: {
								crumb: () => <Link href={routePaths.fileMonitoring.payrollDeduction.path}>Payroll Deduction</Link>,
							},
							children: prepareRoutes(
								[
									{
										index: true,
										lazy: () =>
											import('pages/payroll-deduction-summary/payroll-deduction-summary-page').then((module) => ({
												Component: module.default,
											})),
									},
									{
										path: routePaths.fileMonitoring.payrollDeduction.file.path,
										handle: {
											crumb: (data: UIMatch<any, any>) => (
												<Link
													href={generatePath(routePaths.fileMonitoring.payrollDeduction.file.path, {
														fileId: `${data.params.fileId ?? ''}`,
													})}
												>
													{data.params.fileId ?? '[fileId]'}
												</Link>
											),
										},
										children: prepareRoutes(
											[
												{
													index: true,
													lazy: () =>
														import(
															'pages/file-monitoring/payroll-deduction/payroll-deduction-file-monitoring-details-page'
														).then((module) => ({ Component: module.default })),
												},
												{
													lazy: () =>
														import('pages/payroll-deduction-summary/payroll-deduction-detail-lines').then((module) => ({
															Component: module.default,
														})),
													path: routePaths.fileMonitoring.payrollDeduction.file.lineDetails.path,
													handle: {
														crumb: (data: UIMatch<any, any>) => (
															<Link
																href={generatePath(routePaths.fileMonitoring.payrollDeduction.file.lineDetails.path, {
																	fileId: data.params.fileId ?? '',
																})}
															>
																Payroll Deduction File Details
															</Link>
														),
													},
												},
											],
											{
												stripLeading: `${routePaths.fileMonitoring.payrollDeduction.file.path}/`,
											},
										),
									},
								],
								{
									stripLeading: `${routePaths.fileMonitoring.payrollDeduction.path}/`,
								},
							),
						},
						{
							path: `${routePaths.fileMonitoring.payrollConfirmation.path}/*`,
							flag: transformers11348,
							handle: {
								crumb: () => (
									<Link href={routePaths.fileMonitoring.payrollConfirmation.path}>Payroll Confirmation</Link>
								),
							},
							children: prepareRoutes(
								[
									{
										index: true,
										lazy: () =>
											import('pages/payroll-confirmation-summary/payroll-confirmation-summary-page').then((module) => ({
												Component: module.default,
											})),
									},
									{
										path: routePaths.fileMonitoring.payrollConfirmation.file.path,
										handle: {
											crumb: (data: UIMatch<any, any>) => (
												<Link
													href={generatePath(routePaths.fileMonitoring.payrollConfirmation.file.path, {
														fileId: data.params.fileId ?? '',
													})}
												>
													{data.params.fileId ?? '[fileId]'}
												</Link>
											),
										},
										children: prepareRoutes(
											[
												{
													index: true,
													lazy: () =>
														import(
															// eslint-disable-next-line max-len
															'pages/file-monitoring/payroll-confirmation/payroll-confirmation-file-monitoring-details-page'
														).then((module) => ({ Component: module.default })),
												},
												{
													lazy: () =>
														import('pages/payroll-confirmation-summary/payroll-confirmation-detail-lines').then(
															(module) => ({
																Component: module.default,
															}),
														),
													path: routePaths.fileMonitoring.payrollConfirmation.file.fileLineDetails.path,
													handle: {
														crumb: (data: UIMatch<any, any>) => (
															<Link
																href={generatePath(
																	routePaths.fileMonitoring.payrollConfirmation.file.fileLineDetails.path,
																	{
																		fileId: data.params.fileId ?? '',
																	},
																)}
															>
																Payroll Confirmation File Details
															</Link>
														),
													},
												},
											],
											{
												stripLeading: `${routePaths.fileMonitoring.payrollConfirmation.file.path}/`,
											},
										),
									},
								],
								{
									stripLeading: `${routePaths.fileMonitoring.payrollConfirmation.path}/`,
								},
							),
						},
						{
							path: `${routePaths.fileMonitoring.providerConfirmation.path}/*`,
							flag: transformers11348,
							handle: {
								crumb: () => (
									<Link href={routePaths.fileMonitoring.providerConfirmation.path}>Provider Confirmation</Link>
								),
							},
							children: prepareRoutes(
								[
									{
										index: true,
										lazy: () =>
											import('pages/provider-confirmation-summary/provider-confirmation-summary-page').then(
												(module) => ({
													Component: module.default,
												}),
											),
									},
									{
										path: routePaths.fileMonitoring.providerConfirmation.file.path,
										handle: {
											crumb: (data: UIMatch<any, any>) => (
												<Link
													href={generatePath(routePaths.fileMonitoring.providerConfirmation.file.path, {
														fileId: data.params.fileId ?? '',
													})}
												>
													{data.params.fileId ?? '[fileId]'}
												</Link>
											),
										},
										children: prepareRoutes(
											[
												{
													index: true,
													lazy: () =>
														import(
															// eslint-disable-next-line max-len
															'pages/file-monitoring/provider-confirmation/provider-confirmation-file-monitoring-details-page'
														).then((module) => ({ Component: module.default })),
												},
												{
													lazy: () =>
														import('pages/provider-confirmation-summary/provider-confirmation-summary-page').then(
															(module) => ({
																Component: module.default,
															}),
														),
													path: routePaths.fileMonitoring.providerConfirmation.file.lineDetails.path,
													handle: {
														crumb: (data: UIMatch<any, any>) => (
															<Link
																href={generatePath(
																	routePaths.fileMonitoring.providerConfirmation.file.lineDetails.path,
																	{
																		fileId: data.params.fileId ?? '[fileId]',
																	},
																)}
															>
																Provider Confirmation File Details
															</Link>
														),
													},
												},
											],
											{
												stripLeading: `${routePaths.fileMonitoring.providerConfirmation.file.path}/`,
											},
										),
									},
								],
								{
									stripLeading: `${routePaths.fileMonitoring.providerConfirmation.path}/`,
								},
							),
						},
						{
							path: routePaths.fileMonitoring.providerRemittance.path,
							handle: {
								crumb: () => <Link href={routePaths.fileMonitoring.providerRemittance.path}>Provider Remittance</Link>,
							},
							children: prepareRoutes(
								[
									{
										index: true,
										lazy: () =>
											import('pages/provider-remittance-files-summary').then((module) => ({
												Component: module.default,
											})),
									},
									{
										path: routePaths.fileMonitoring.providerRemittance.file.path,
										handle: {
											crumb: (data: UIMatch<any, any>) => (
												<Link
													href={generatePath(routePaths.fileMonitoring.providerRemittance.file.path, {
														remittanceFileId: data.params.remittanceFileId ?? '',
													})}
												>
													{data.params.remittanceFileId ?? '[remittanceFileId]'}
												</Link>
											),
										},
										children: prepareRoutes(
											[
												{
													index: true,
													lazy: () =>
														import(
															// eslint-disable-next-line max-len
															'pages/file-monitoring/provider-remittance/provider-remittance-file-monitoring-details-page'
														).then((module) => ({ Component: module.default })),
												},
											],
											{ stripLeading: `${routePaths.fileMonitoring.providerRemittance.file.path}/` },
										),
									},
								],
								{ stripLeading: `${routePaths.fileMonitoring.providerRemittance.path}/` },
							),
						},
					],
					{ stripLeading: `${routePaths.fileMonitoring.root}/` },
				),
			},
		],
		{},
	)

	return routes
}
