import { Environments } from 'utils/env'
/**
 * From the environment, get the correct LD client id configured in the .env files
 */
export function getLdClientIdForEnv(env: Environments) {
	const ldClientIds = {
		[Environments.DEV]: import.meta.env.VITE_LD_ID_DEV,
		[Environments.INT]: import.meta.env.VITE_LD_ID_INT,
		[Environments.UAT]: import.meta.env.VITE_LD_ID_UAT,
	}

	const ldClientId = ldClientIds[env] || import.meta.env.VITE_LD_ID

	return ldClientId
}
